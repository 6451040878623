export enum START_TIME_STATUSES {
  available = 'available',
  unavailable = 'unavailable'
}

export enum UNAVAILABLE_REASONS {
  pastMinimumNotice = 'Current is not suitably before timeslot',
  resourcesUnavailable = 'Reasource requirements can not be met'
}

export const RENTALS_TITLE = 'Rentals'
