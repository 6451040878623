import { defined } from 'shared/presenters/presenter'
const determineSessionLocationURL = () => {
  const api_path = '/internal_api/session_location'
  const api_domain = defined(process.env.NEXT_PUBLIC_EOLA_ROOT).replace(
    'https://',
    'https://backend.'
  )
  return `${api_domain}${api_path}`
}

export default determineSessionLocationURL
