import { AfterModifier, Modifier } from 'react-day-picker'

/**
 * Takes a react-day-picker Modifier or Modifier[] type, and "localises" Dates to the users timezone
 * i.e. '2022-01-25T12:00:00.000Z' -> 'Tue Jan 25 2022 00:00:00' (in local timezone)
 *
 * This function is only required due to a problem in react-day-picker's handling of datetimes
 * (when passed a UTC date-time, react-day-picker will still "localise" it to the browser's timezone,
 * potentially altering it to a different date)
 */
export const localiseDayPickerModifier = (days: Modifier | Modifier[]) => {
  if (!dayPickerModifierHasValue(days)) return []

  if (Array.isArray(days)) {
    return days.map(day => removeTimeFromModifier(day))
  }

  return [removeTimeFromModifier(days)]
}

/**
 * Checks a react-day-picker Modifier type has value
 */
export const dayPickerModifierHasValue = (days?: Modifier | Modifier[]) => {
  if (!days) return false
  if (Array.isArray(days)) return days.length > 0
  return !!days
}

/**
 * Localises a UTC Date object, discarding the time
 *
 * This does not actually remove the time as otherwise react day picker
 * adds it back wrong. Instead set to 3am to prevent any issues during DST
 * Changeovers
 */
export const removeTimeFromDate = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    3,
    0,
    0
  )

/**
 * Adjust a Date object so that the UTC day/date is consistent regardless of timezone.
 *
 * E.g given a string of 2024-10-20, a new Date object would be as follows:
 * GMT - 1:00 = Oct 20 2024 00:00:00 GMT-0100, UTC = "2024-10-20T01:00:00.000Z"
 * GMT + 1:00 = Oct 20 2024 00:00:00 GMT+0100, UTC = "2024-10-19T23:00:00.000Z" - day before
 *
 * After calling this method, UTC date/time will be the same for both = 2024-10-20T00:00:00.000Z
 */
export const timezoneAdjustDate = (date: Date) => {
  if (date.getTimezoneOffset() < 0) {
    date.setTime(date.getTime() - date.getTimezoneOffset() * 60000)
  } else {
    date.setTime(date.getTime() + date.getTimezoneOffset() * 60000)
  }
  return date
}

/**
 * Identifies modifier before removing the time from the Date
 */
const removeTimeFromModifier = (day: Modifier) => {
  const afterModifier = (day as AfterModifier)?.after

  if (day instanceof Date) {
    return removeTimeFromDate(day)
  }
  if (afterModifier) {
    return { after: removeTimeFromDate(afterModifier) }
  }

  throw new Error(
    'This modifier has not been covered by this function yet. Please, add it to the rest of the logic.'
  )
}
