import { DEFAULT_TITLES } from 'enums/topLevelChargeables'
import { Translate } from 'next-translate'

const translateChargeableTitle = (title: string, t: Translate) => {
  // adding type assert 'as any'to workaround .includes limitations
  // https://github.com/microsoft/TypeScript/issues/26255
  return (DEFAULT_TITLES as string[]).includes(title)
    ? t(`common:${title.toLowerCase()}`)
    : title
}

export default translateChargeableTitle
