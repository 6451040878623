import { present, defined, definedNotNull } from 'shared/presenters/presenter'
import { Disclaimers } from 'shared/presenters/graphqlTypes'

export class DisclaimerPresenter extends present<Disclaimers>() {
  for_user = () => {
    if (defined(this.child)?.name) {
      return definedNotNull(this.child)?.name
    }
    return definedNotNull(this?.enthusiast)?.name
  }
}
