import React, { ReactNode } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { generateCloudinaryUrl } from 'shared/helpers/generateCloudinaryUrl'
import { EOLA_ROUTES } from 'enums/routes'

interface HeadProps {
  title: string
  description: string
  keywords: string
  image?: string
  children?: ReactNode
}

/**
 * <head> container for metadata, it has the most common props we need to use for good SEO.
 */
const HeadContainer = ({
  title,
  description,
  keywords,
  image,
  children
}: HeadProps) => {
  const { route } = useRouter()

  const srcImage = image && generateCloudinaryUrl(image, 'thumb')

  return (
    <Head>
      <title>{`eola | ${title}`}</title>
      <meta name="description" content={description}></meta>
      <meta name="keywords" content={keywords}></meta>
      <meta property="og:title" content={title} />
      <meta property="og:type" content="WebSite" />
      <meta property="og:url" content={`${EOLA_ROUTES.HOME.url()}${route}`} />
      {image && (
        <>
          <meta property="og:image" content={srcImage} />
        </>
      )}
      {children}
    </Head>
  )
}

export default HeadContainer
