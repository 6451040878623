import { ActiveModalProps, ModalConfig } from 'shared/library/molecules/Modal'
import usePageRouter from './usePageRouter'

/**
 * The modalPropVariations are meant to don't have the the setActiveModalId because it is added by useModal.
 */
type RecursiveAddSetActiveModalId<T> = {
  [K in keyof T]: T[K] & Pick<ActiveModalProps, 'setActiveModalId'>
}

// The `any` will be replaced with the variable type we use while calling this function.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UseModal = <T extends { [key: string]: any }>(arg: {
  MODAL_CONFIG: ModalConfig
  modalPropVariations?: T
}) => {
  currentModalProps: RecursiveAddSetActiveModalId<T>
} & ActiveModalProps

/**
 * Generate variables to manage the active modal.
 */
const useModal: UseModal = ({ MODAL_CONFIG, modalPropVariations }) => {
  const { activeModalId, setActiveModalId } = usePageRouter()

  const activeModal = activeModalId ? MODAL_CONFIG[activeModalId] : null

  /**
   * It returns a type `any` because we are using a generic type variable.
   * The `any` will be replaced with the variable type we use while calling this function.
   */
  const modalProps =
    activeModalId && modalPropVariations
      ? modalPropVariations[activeModalId]
      : {}

  const currentModalProps = {
    ...modalProps,
    setActiveModalId
  }

  return {
    activeModal,
    activeModalId,
    setActiveModalId,
    currentModalProps
  }
}

export default useModal
