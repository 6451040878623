import React, { ReactNode } from 'react'
import Icon from 'shared/library/atoms/Icon'
import styled from '@emotion/styled'
import { A11y, Autoplay } from 'swiper'
import { Swiper, SwiperProps, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import { useTheme } from '@emotion/react'
import { defaultTheme as theme } from 'assets/theme/theme'
import { ArrayElement } from 'shared/helpers/utilityTypes'
import Button from 'library/atoms/Button'
import useTranslation from 'next-translate/useTranslation'
import Flex from 'shared/library/atoms/Flex'
import { determineNodeEnvironment } from 'shared/helpers/determineEnvironment'

interface Children {
  children?: ReactNode
}

interface ClassName {
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
}

interface CarouselSettings extends Omit<SwiperProps, 'spaceBetween'> {
  spaceBetween?: ArrayElement<typeof theme.space>
}

interface CarouselProps extends Children, ClassName {
  /**
   * Visit Swiper docs to learn all the possible configurations.
   * @link https://swiperjs.com/demos
   */
  settings?: CarouselSettings
}

interface CarouselSlotProps extends Children, ClassName {
  /**
   * Swiper uses "slots" for content distribution. It allows us to inject components inside the Swiper if
   * these components are not slides, visit the link below for more information.
   * @link https://swiperjs.com/react#slots
   *
   * @note We are not getting the following type from Swiper because they don't provide it at the moment.
   */
  slot: 'container-start' | 'container-end' | 'wrapper-start' | 'wrapper-end'
}

const Carousel = ({ settings, children, className }: CarouselProps = {}) => {
  const theme = useTheme()

  const DEFAULT_SETTINGS: SwiperProps = {
    modules: [A11y, Autoplay],
    spaceBetween: theme.space[settings?.spaceBetween ?? 2],
    /**
     * The value "auto" allows CarouselSlide to determine the width.
     * But we can also provide a number if we need the slides per view to be a fixed number.
     */
    slidesPerView: 'auto',
    loop: true,
    mousewheel: true,
    keyboard: true,
    a11y: {
      enabled: true,
      /**
       * Remove dynamic id while running tests in order to pass snapshot tests.
       */
      id: determineNodeEnvironment()?.isTest ? 'swiper-wrapper-id' : undefined
    },
    /**
     * grabCursor doesn't work if the settings also have { cssMode: true }
     */
    grabCursor: true,
    autoplay: false,
    speed: 500
  }

  const carouselSettings = { ...DEFAULT_SETTINGS, ...settings }

  return (
    <StyledCarousel {...carouselSettings} className={className}>
      {children}
    </StyledCarousel>
  )
}

export default Carousel

/**
 * This component can't be styled, otherwise, the carousel will break.
 *
 * The way how the styled component modifies this component to introduce the Emotion's logic
 * isn't compatible with SwiperSlide. We are still able to style its children or target the
 * SwiperSlide via the approach below:
 *
 * const StyledCarousel = styled(Carousel)({ '.swiper-slide': { width: 240 } })
 */
export { SwiperSlide as CarouselSlide }

/**
 * Carousel Navigation
 *
 * It has to be called as children of the Carousel component.
 */
export const CarouselNavigation = ({ className }: ClassName) => {
  const swiper = useSwiper()
  const { t } = useTranslation('common')

  return (
    <Flex gap={4} className={className}>
      <Button
        variant="icon"
        onClick={() => swiper.slidePrev()}
        aria-label={t('show-previous-slide')}
      >
        <Icon name="arrow-left" />
      </Button>
      <Button
        variant="icon"
        onClick={() => swiper.slideNext()}
        aria-label={t('show-next-slide')}
      >
        <Icon name="arrow-right" />
      </Button>
    </Flex>
  )
}

/**
 * Use CarouselSlot to render components inside the Carousel.
 *
 * It allows us to inject components inside the carousel if these components
 * are not slides.
 *
 * Swiper doesn't allow random children and expects the children to be components
 * provided by Swiper. Anything else that is not a Swiper's component needs to be
 * rendered via the `slot` prop. Visit the link below for more information.
 * @link https://swiperjs.com/react#slots
 *
 *
 * @example
 *
 * <Carousel>
 *
 *   <CarouselSlot slot="container-start">
 *     <h2>Ttiel</h2>
 *     <CarouselNavigation />
 *   </CarouselSlot>
 *
 *   <CarouselSlide />
 *   <CarouselSlide />
 *   <CarouselSlide />
 *
 * </Carousel>
 *
 * @note
 * Read the type CarouselSlotProps for more information.
 */
export const CarouselSlot = ({
  slot,
  className,
  children
}: CarouselSlotProps) => (
  <div slot={slot} className={className}>
    {children}
  </div>
)

const StyledCarousel = styled(Swiper)(() => ({
  width: '100%',
  '.swiper-wrapper': {
    transitionTimingFunction: theme.transitionTimingFunction.smooth
  },
  '.swiper-slide': {
    display: 'flex',
    height: 'unset'
  }
}))
