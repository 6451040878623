export const MEMBERSHIPS_IMAGE_URL =
  'https://res.cloudinary.com/eola/image/upload/q_auto:eco,f_auto/v1634031950/eola/public_images/membership-default-cover-image.webp'

export const MEMBERSHIPS_TITLE = 'Memberships'
export const MEMBERSHIP_TITLE = 'Membership'

export const MEMBERSHIPS_DURATION_TRANSLATION: {
  [key: string]: string
} = {
  day: 'x-days-duration',
  week: 'x-weeks-duration',
  month: 'x-months-duration',
  year: 'x-years-duration'
}
