import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import EolaHeader from 'library/molecules/EolaHeader'
import EolaFooter from 'library/molecules/EolaFooter'

interface EolaLayoutProps {
  children?: ReactNode
  /**
   * Takes a string to output a class. Related to emotion - It lets another component style it
   */
  className?: string
}

const EolaLayout = ({ children, className }: EolaLayoutProps) => {
  return (
    <>
      <StyledLayout className={className}>
        <EolaHeader />
        {children}
      </StyledLayout>
      <EolaFooter />
    </>
  )
}

export default EolaLayout

const StyledLayout = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.mixins.pxSpread([theme.space[3], 0, 0]),
  background: theme.colors.eola.layout.background.primary,
  // Common space between sections
  '> * + *:not(main):not(header)': {
    marginTop: theme.space[5]
  },
  // Targeting <a> without a class so that styled-component derived classNames can override these defaults.
  'a:not([class])': {
    ...theme.mixins.eola.link
  },
  [theme.mediaQueries.md]: {
    padding: theme.mixins.pxSpread([
      theme.space[4],
      theme.space[7],
      theme.space[7]
    ]),
    '> * + *:not(main)': {
      marginTop: theme.space[6]
    }
  }
}))
