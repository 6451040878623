import styled from '@emotion/styled'
import { H5 } from 'library/atoms/Typography'
import Button from 'library/atoms/Button'
import Icon from 'shared/library/atoms/Icon'
import { SetActiveModalId } from 'shared/library/molecules/Modal'

const ModalHeader = ({
  onClose,
  headingText
}: {
  onClose: SetActiveModalId<null>
  headingText: string
}) => {
  return (
    <StyledModalHeader>
      <H5>{headingText}</H5>
      <Button variant="icon" onClick={onClose}>
        <Icon name="close" color="grey" size={24} />
      </Button>
    </StyledModalHeader>
  )
}

export default ModalHeader

const StyledModalHeader = styled.header(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 60,
  padding: theme.space[4],
  borderBottom: theme.border,
  background: theme.colors.white
}))
