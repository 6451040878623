//TODO: refactor VOUCHERS_IMAGE_URL to use generateCloudinaryUrl #5547
export const VOUCHERS_IMAGE_URL =
  'https://res.cloudinary.com/eola/image/upload/t_schedulable_default/v1671115915/eola/public_images/vouchers.png'

export const VOUCHERS_THUMBNAIL_URL =
  'image/upload/v1656538543/eola/public_images/post-checkout-voucher.jpg'

export const VOUCHERS_TITLE = 'Vouchers-and-passes'

export const CREDIT_VOUCHER_TITLE = 'Credit Voucher'

export const CREDIT_VOUCHER_DESCRIPTION = `Vouchers are the perfect present.
Fantastically flexible - they can be used with any of the activities we offer.
Buy one now, and use it within a year.`

export const CREDIT_VOUCHER_MIN_PRICE_PENCE = 500
