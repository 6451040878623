import React, { MouseEvent } from 'react'
import Icon from 'shared/library/atoms/Icon'
import { H6 } from 'library/atoms/Typography'
import styled from '@emotion/styled'
import Tag from 'library/atoms/Tag'

export interface LocationTagProps {
  text?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

const LocationTag = ({ text, onClick }: LocationTagProps) => {
  return (
    <StyledLocation variant="icon" onClick={onClick}>
      <Icon name="pin" size={20} mr={3} />
      <H6>{text}</H6>
    </StyledLocation>
  )
}

export default LocationTag

const StyledLocation = styled(Tag)(({ theme }) => ({
  marginTop: 'auto',
  '&:hover': {
    ...theme.mixins.iconOnHover
  }
}))
